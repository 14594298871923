import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import API from '@/services/api'
import moment from 'moment'

export const printPackagePDF = async (
    packingList,
    project,
    packageElement,
    packageIndex
) => {
    try {
        const {
            data: { settings },
        } = await API.getSettings()
        let company = settings.find(s => s.name == 'Company').generalInfo
        let settingFinish = settings.find(s => s.name == 'FinishAndUCCode')
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [216, 279],
        })
        // set header and footer
        const aspectRatio = await getAspectRatio(company.logoBase64)

        const header = function(data) {
            pdf.setFontSize(16)
            pdf.setFont('helvetica', 'bold')
            const text = `Packing List ${packingList.code} | ${project.reference} ${project.name}`
            const maxWidth = 150
            const marginLeft = data.settings.margin.left
            const marginTop = 15
            const splitText = pdf.splitTextToSize(text, maxWidth)
            pdf.text(splitText, marginLeft, marginTop)
            const linesQty = splitText.length
            const startY = 19 + linesQty * 4

            pdf.setFontSize(14)
            pdf.setFont('helvetica', 'normal')
            pdf.text(
                `Package ${packageIndex}`,
                data.settings.margin.left,
                startY
            )
            // add company logo
            const imgHeight = 20
            const imgWidth = imgHeight * aspectRatio
            const imgX =
                pdf.internal.pageSize.width -
                data.settings.margin.right -
                imgWidth
            const imgY = 10
            pdf.addImage(
                company.logoBase64,
                'PNG',
                imgX,
                imgY,
                imgWidth,
                imgHeight
            )
        }

        const footer = function(data) {
            pdf.setLineWidth(0.5)
            pdf.line(
                10,
                pdf.internal.pageSize.height - 10,
                pdf.internal.pageSize.width - 10,
                pdf.internal.pageSize.height - 10
            )

            pdf.setFontSize(12)
            pdf.setFont('helvetica', 'normal')
            pdf.text(
                `Printed On ${moment().format('YYYY-MM-DD HH:mm A')}`,
                data.settings.margin.left,
                pdf.internal.pageSize.height - 5
            )

            pdf.setFontSize(12)
            pdf.text(
                `${data.pageNumber}`,
                pdf.internal.pageSize.width - 13,
                pdf.internal.pageSize.height - 5
            )
        }

        const fontSize = 10
        pdf.setFontSize(fontSize)
        const totalWidth = pdf.internal.pageSize.width - 20

        // desc options
        let descriptionOptions = {
            margin: { top: 35, left: 10, right: 10 },
            startY: 40,
            headStyles: {
                fillColor: [211, 211, 211],
                textColor: [0, 0, 0],
            },
            didDrawPage: function(data) {
                header(data)
                footer(data)
            },
        }

        // pick up
        pdf.autoTable({
            head: [['Width', 'Height', 'Lenght', 'Crate Mass', 'Crate BOM']],
            body: [
                [
                    `${packageElement.packageDimensions.width ||
                        ''} ${packageElement.packageDimensions.units || ''}`,
                    `${packageElement.packageDimensions.height ||
                        ''} ${packageElement.packageDimensions.units || ''}`,
                    `${packageElement.packageDimensions.depth ||
                        ''} ${packageElement.packageDimensions.units || ''}`,
                    `${packageElement.packageDimensions.mass || ''} Kg`,
                    `${packageElement.packageBOM || ''}`,
                ],
                [],
            ],
            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 0.2 },
                1: { cellWidth: totalWidth * 0.2 },
                2: { cellWidth: totalWidth * 0.2 },
                3: { cellWidth: totalWidth * 0.2 },
                4: { cellWidth: totalWidth * 0.2 },
            },
        })

        descriptionOptions.startY = pdf.autoTable.previous.finalY - 7

        descriptionOptions.startY = pdf.autoTable.previous.finalY - 5
        descriptionOptions.headStyles = {
            fillColor: [41, 128, 186],
            textColor: [256, 256, 256],
        }

        // calculate package mass
        const packageMass = packageElement.items
            ? packageElement.items
                  .reduce(
                      (accumulator, item) =>
                          accumulator + item.packingQty * item.mass,
                      0
                  )
                  .toFixed(1)
            : 0
        // calculate package qty
        const packageQty = packageElement.items
            ? packageElement.items
                  .reduce(
                      (accumulator, item) => accumulator + item.packingQty,
                      0
                  )
                  .toFixed(0)
            : 0
        // add table
        pdf.autoTable({
            head: [
                [
                    'Item',
                    'Work Order',
                    'Item Code',
                    'Description',
                    'Finish & UC Code',
                    'Qty',
                    'Weight',
                    'Total',
                    'Notes',
                ],
            ],
            body: [
                ...packageElement.items.map((item, index) => [
                    index + 1,
                    item.bom ? 'BOM ' + item.bom : 'WO ' + item.workOrder,
                    item.bom ? item.code : item.partNumber,
                    item.description,
                    processFinish(item, settingFinish),
                    item.packingQty,
                    `${(+item.mass || 0).toFixed(2)} Kg`,
                    `${formatNumber(item.packingMass)} Kg`,
                    item.notes,
                ]),
            ],
            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 0.06 },
                1: { cellWidth: totalWidth * 0.12 },
                2: { cellWidth: totalWidth * 0.12 },
                3: { cellWidth: totalWidth * 0.14 },
                4: { cellWidth: totalWidth * 0.14 },
                5: { cellWidth: totalWidth * 0.09 },
                6: { cellWidth: totalWidth * 0.1 },
                7: { cellWidth: totalWidth * 0.1 },
                8: { cellWidth: totalWidth * 0.13 },
            },
        })
        descriptionOptions.startY = pdf.autoTable.previous.finalY + 5

        // Add totals header
        pdf.setFont('helvetica', 'bold')
        pdf.setFillColor(230, 230, 230)
        pdf.rect(
            10,
            descriptionOptions.startY,
            pdf.internal.pageSize.width - 20,
            10,
            'F'
        )

        const headers = [
            'TOTAL',
            `PART NUMBERS: ${Number(packageElement.items.length || 0).toFixed(
                0
            )}`,
            `ITEMS: ${Number(packageQty || 0).toFixed(0)}`,
            `NET MASS: ${formatNumber(Number(packageMass))} Kg`,
            `GROSS MASS: ${formatNumber(
                Number(packageMass) +
                    Number(packageElement.packageDimensions.mass || 0)
            )} Kg`,
        ]
        const cellWidths = [
            totalWidth * 0.11,
            totalWidth * 0.21,
            totalWidth * 0.15,
            totalWidth * 0.24,
            totalWidth * 0.26,
        ]

        let currentX = 0
        headers.forEach((header, index) => {
            pdf.text(header, currentX + 15, descriptionOptions.startY + 6)
            currentX += cellWidths[index]
        })

        const pdfDataUri = pdf.output('datauristring')
        const blob = dataURItoBlob(pdfDataUri)
        const blobURL = URL.createObjectURL(blob)
        const printWindow = window.open(blobURL)
        printWindow.onload = function() {
            printWindow.print()
            URL.revokeObjectURL(blobURL)
        }
    } catch (error) {
        console.log(error)
    }
}

const getAspectRatio = async file => {
    return new Promise(function(resolved) {
        var i = new Image()
        i.onload = function() {
            resolved(i.width / i.height)
        }
        i.src = file
    })
}

const dataURItoBlob = dataURI => {
    const byteString = Uint8Array.from(atob(dataURI.split(',')[1]), c =>
        c.charCodeAt(0)
    )
    const blobType = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]
    return new Blob([byteString], { type: blobType })
}

const processFinish = (item, settingFinish) => {
    if (item.finishAndUCCode && settingFinish) {
        const finish = settingFinish.finishes.find(
            e => e.id === item.finishAndUCCode
        )
        if (finish) {
            return `${finish.description}`
        } else {
            return '-'
        }
    } else {
        return '-'
    }
}
const formatNumber = value => {
    value = Number(value)
    if (isNaN(value)) {
        return value
    }
    return value.toFixed(2)
}
