import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import API from '@/services/api'

export const printPackingPDF = async (
    packingList,
    project,
    useSuggested = false
) => {
    try {
        const {
            data: { settings },
        } = await API.getSettings()
        let company = settings.find(s => s.name == 'Company').generalInfo
        let settingFinish = settings.find(s => s.name == 'FinishAndUCCode')
        const {
            data: { users },
        } = await API.getUsers()
        let pdf = new jsPDF({
            orientation: 'portrait ',
            unit: 'mm',
            format: [216, 279],
        })
        if (useSuggested) {
            pdf = new jsPDF({
                orientation: 'landscape ',
                unit: 'mm',
                format: [279, 216],
            })
        }

        // set header and footer
        const aspectRatio = await getAspectRatio(company.logoBase64)

        const header = function(data) {
            pdf.setFontSize(16)
            pdf.setFont('helvetica', 'bold')
            const text = `Packing List ${packingList.code} | ${project.reference} ${project.name}`
            const maxWidth = 150
            const marginLeft = data.settings.margin.left
            const marginTop = 15
            const splitText = pdf.splitTextToSize(text, maxWidth)
            pdf.text(splitText, marginLeft, marginTop)
            const linesQty = splitText.length
            const startY = 19 + linesQty * 4

            pdf.setFontSize(14)
            pdf.setFont('helvetica', 'normal')
            pdf.text(
                `${moment
                    .unix(
                        packingList.createdOn.seconds ||
                            packingList.createdOn._seconds
                    )
                    .format('YYYY-MM-DD')}`,
                data.settings.margin.left,
                startY
            )
            // add company logo
            const imgHeight = 20
            const imgWidth = imgHeight * aspectRatio
            const imgX =
                pdf.internal.pageSize.width -
                data.settings.margin.right -
                imgWidth
            const imgY = 10
            pdf.addImage(
                company.logoBase64,
                'PNG',
                imgX,
                imgY,
                imgWidth,
                imgHeight
            )
        }

        const footer = function(data) {
            pdf.setLineWidth(0.5)
            pdf.line(
                10,
                pdf.internal.pageSize.height - 10,
                pdf.internal.pageSize.width - 10,
                pdf.internal.pageSize.height - 10
            )

            pdf.setFontSize(12)
            pdf.text(
                `${data.pageNumber}`,
                pdf.internal.pageSize.width - 13,
                pdf.internal.pageSize.height - 5
            )
        }

        const fontSize = 10
        pdf.setFontSize(fontSize)
        const projectManager = findUserInfo(users, project.projectManager)
        const totalWidth = pdf.internal.pageSize.width - 20

        // desc options
        let descriptionOptions = {
            margin: { top: 35, left: 10, right: 10 },
            startY: 40,
            headStyles: {
                fillColor: [211, 211, 211],
                textColor: [0, 0, 0],
            },
            didDrawPage: function(data) {
                header(data)
                footer(data)
            },
        }
        // pick up
        pdf.autoTable({
            head: [
                [
                    'Remission',
                    'Address',
                    'City',
                    'PL Date',
                    'Contact',
                    'Phone',
                    'Pick up',
                ],
            ],
            body: [
                [
                    packingList.remission ? packingList.remission : '-',
                    'ESM',
                    'Barranquilla',
                    moment
                        .unix(
                            packingList.createdOn.seconds ||
                                packingList.createdOn._seconds
                        )
                        .format('YYYY-MM-DD'),
                    projectManager.name,
                    projectManager.phone,
                    packingList.shippedOn
                        ? moment
                              .unix(
                                  packingList.shippedOn.seconds ||
                                      packingList.shippedOn._seconds
                              )
                              .format('YYYY-MM-DD')
                        : '-',
                ],
                [],
            ],
            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 0.12 },
                1: { cellWidth: totalWidth * 0.12 },
                2: { cellWidth: totalWidth * 0.13 },
                3: { cellWidth: totalWidth * 0.13 },
                4: { cellWidth: totalWidth * 0.2 },
                5: { cellWidth: totalWidth * 0.17 },
                6: { cellWidth: totalWidth * 0.13 },
            },
        })

        descriptionOptions.startY = pdf.autoTable.previous.finalY - 7
        // delivery
        let contact = ''
        if (
            packingList.deliveryInformation &&
            packingList.deliveryInformation.contact &&
            typeof packingList.deliveryInformation.contact == 'string'
        ) {
            contact = packingList.deliveryInformation.contact
        } else if (
            packingList.deliveryInformation &&
            packingList.deliveryInformation.contact &&
            typeof packingList.deliveryInformation.contact == 'object' &&
            packingList.deliveryInformation.contact.name
        ) {
            contact = packingList.deliveryInformation.contact.name
        }
        pdf.autoTable({
            head: [['Container', 'Address', 'Contact', 'Phone', 'Delivery']],
            body: [
                [
                    packingList.container ? packingList.container : '-',
                    packingList.deliveryInformation
                        ? packingList.deliveryInformation.location
                            ? packingList.deliveryInformation.location
                            : '-'
                        : '-',
                    packingList.deliveryInformation
                        ? packingList.deliveryInformation.contact
                            ? contact
                            : '-'
                        : '-',
                    packingList.deliveryInformation
                        ? packingList.deliveryInformation.phone
                            ? packingList.deliveryInformation.phone
                            : '-'
                        : '-',
                    packingList.completedOn
                        ? moment
                              .unix(
                                  packingList.completedOn.seconds ||
                                      packingList.completedOn._seconds
                              )
                              .format('YYYY-MM-DD')
                        : '-',
                ],
                [],
            ],
            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 0.2 },
                1: { cellWidth: totalWidth * 0.2 },
                2: { cellWidth: totalWidth * 0.2 },
                3: { cellWidth: totalWidth * 0.2 },
                4: { cellWidth: totalWidth * 0.2 },
            },
        })

        descriptionOptions.startY = pdf.autoTable.previous.finalY - 7
        // vendor
        pdf.autoTable({
            head: [['Vendor Name', 'NIT', 'Address', 'City', 'Scope']],
            body: [
                [
                    'C.I. ES METALS S.A.S | www.es-metals.com',
                    '901.240.800-3',
                    'Carrera 72A # 107-33',
                    'Barranquilla, Colombia',
                    packingList.scopes ? packingList.scopes : '-',
                ],
                [],
            ],
            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 0.2 },
                1: { cellWidth: totalWidth * 0.14 },
                2: { cellWidth: totalWidth * 0.2 },
                3: { cellWidth: totalWidth * 0.2 },
                4: { cellWidth: totalWidth * 0.26 },
            },
        })
        descriptionOptions.startY = pdf.autoTable.previous.finalY - 7
        // notes
        pdf.autoTable({
            head: [['Notes']],
            body: [[packingList.notes ? packingList.notes : '-'], []],
            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 1 },
            },
        })

        descriptionOptions.startY = pdf.autoTable.previous.finalY - 5
        descriptionOptions.headStyles = {
            fillColor: [41, 128, 186],
            textColor: [256, 256, 256],
        }
        let index = 0
        let totalItems = 0
        let totalMass = 0
        let netMass = 0
        let totalSuggestedMass = 0
        let netSuggestedMass = 0
        let totalSuggestedItems = 0

        packingList.packages.forEach(pck => {
            index++

            // calculate package mass
            const packageMass = pck.items
                ? pck.items
                      .reduce(
                          (accumulator, item) =>
                              accumulator + item.packingQty * item.mass,
                          0
                      )
                      .toFixed(1)
                : 0
            totalMass += Number(packageMass)
            netMass += Number(packageMass)
            totalMass += Number(pck.packageDimensions.mass || 0)
            //calculate total suggested mass
            const suggestedMass = pck.items
                ? pck.items
                      .reduce(
                          (accumulator, item) =>
                              accumulator +
                              (item.suggestedQty || 0) * item.mass,
                          0
                      )
                      .toFixed(1)
                : 0

            totalSuggestedMass += Number(suggestedMass)
            netSuggestedMass += Number(suggestedMass)
            totalSuggestedMass += Number(pck.packageDimensions.mass || 0)
            // calculate package qty
            const packageQty = pck.items
                ? pck.items
                      .reduce(
                          (accumulator, item) => accumulator + item.packingQty,
                          0
                      )
                      .toFixed(0)
                : 0

            // calculate suggested qty
            const suggestedQty = pck.items
                ? pck.items
                      .reduce((acc, i) => acc + Number(i.suggestedQty || 0), 0)
                      .toFixed(0)
                : 0
            totalItems += Number(packageQty)
            totalSuggestedItems += Number(suggestedQty || 0)
            // add table
            //suggested table
            if (useSuggested) {
                pdf.autoTable({
                    head: [
                        [
                            {
                                content: `PACKAGE ${index}  (W${
                                    pck.packageDimensions.width
                                } x H${pck.packageDimensions.height} x L${
                                    pck.packageDimensions.depth
                                } ${
                                    pck.packageDimensions.units
                                } - Crate Mass: ${
                                    pck.packageDimensions &&
                                    pck.packageDimensions.mass
                                        ? pck.packageDimensions.mass
                                        : 0
                                } Kg - BOM: ${
                                    pck.packageBOM ? pck.packageBOM : ''
                                })`,
                                colSpan: 11,
                                styles: {
                                    halign: 'left',
                                },
                            },
                        ],
                        [
                            'Item',
                            'Work Order',
                            'Item Code',
                            'Description',
                            'Finish & UC Code',
                            'Suggested Qty',
                            'Confirmed Qty',
                            'Weight',
                            'Suggested Mass',
                            'Confirmed Mass',
                            'Notes',
                        ],
                    ],
                    body: [
                        ...pck.items.map((item, index) => [
                            index + 1,
                            item.bom
                                ? 'BOM ' + item.bom
                                : 'WO ' + item.workOrder,
                            item.bom ? item.code : item.partNumber,
                            item.description,
                            processFinish(item, settingFinish),
                            item.suggestedQty || 0,
                            item.packingQty,
                            `${(+item.mass || 0).toFixed(2)} Kg`,
                            `${formatNumber(
                                (item.suggestedQty || 0) * item.mass
                            )} Kg`,
                            `${formatNumber(item.packingMass)} Kg`,
                            item.notes,
                        ]),
                        [
                            '',
                            'Total',
                            '',
                            '',
                            '',
                            `${Number(suggestedQty).toFixed(0)}`,
                            `${Number(packageQty).toFixed(0)}`,
                            '',
                            `${formatNumber(Number(suggestedMass))} kg`,
                            `${formatNumber(Number(packageMass))} kg`,
                            '',
                        ],
                    ],
                    ...descriptionOptions,
                    columnStyles: {
                        0: { cellWidth: totalWidth * 0.05 },
                        1: { cellWidth: totalWidth * 0.09 },
                        2: { cellWidth: totalWidth * 0.09 },
                        3: { cellWidth: totalWidth * 0.09 },
                        4: { cellWidth: totalWidth * 0.09 },
                        5: { cellWidth: totalWidth * 0.09 },
                        6: { cellWidth: totalWidth * 0.09 },
                        7: { cellWidth: totalWidth * 0.09 },
                        8: { cellWidth: totalWidth * 0.11 },
                        9: { cellWidth: totalWidth * 0.11 },
                        10: { cellWidth: totalWidth * 0.09 },
                    },
                })
            } else {
                pdf.autoTable({
                    head: [
                        [
                            {
                                content: `PACKAGE ${index}  (W${
                                    pck.packageDimensions.width
                                } x H${pck.packageDimensions.height} x L${
                                    pck.packageDimensions.depth
                                } ${
                                    pck.packageDimensions.units
                                } - Crate Mass: ${
                                    pck.packageDimensions &&
                                    pck.packageDimensions.mass
                                        ? pck.packageDimensions.mass
                                        : 0
                                } Kg - BOM: ${
                                    pck.packageBOM ? pck.packageBOM : ''
                                })`,
                                colSpan: 11,
                                styles: {
                                    halign: 'left',
                                },
                            },
                        ],
                        [
                            'Item',
                            'Work Order',
                            'Item Code',
                            'Description',
                            'Finish & UC Code',
                            'Qty',
                            'Weight',
                            'Total',
                            'Notes',
                            'OC',
                            'Position',
                        ],
                    ],
                    body: [
                        ...pck.items.map((item, index) => [
                            index + 1,
                            item.bom
                                ? 'BOM ' + item.bom
                                : 'WO ' + item.workOrder,
                            item.bom ? item.code : item.partNumber,
                            item.description,
                            processFinish(item, settingFinish),
                            item.packingQty,
                            `${(+item.mass || 0).toFixed(2)} Kg`,
                            `${formatNumber(item.packingMass)} Kg`,
                            item.notes,
                            item.oc ?? '',
                            item.costPosition ?? '',
                        ]),
                        [
                            '',
                            'Total',
                            '',
                            '',
                            '',
                            `${Number(packageQty).toFixed(0)}`,
                            '',
                            `${formatNumber(Number(packageMass))} kg`,
                            '',
                            '',
                            '',
                        ],
                    ],
                    ...descriptionOptions,
                    columnStyles: {
                        0: { cellWidth: totalWidth * 0.06 },
                        1: { cellWidth: totalWidth * 0.09 },
                        2: { cellWidth: totalWidth * 0.1 },
                        3: { cellWidth: totalWidth * 0.12 },
                        4: { cellWidth: totalWidth * 0.1 },
                        5: { cellWidth: totalWidth * 0.08 },
                        6: { cellWidth: totalWidth * 0.08 },
                        7: { cellWidth: totalWidth * 0.08 },
                        8: { cellWidth: totalWidth * 0.08 },
                        9: { cellWidth: totalWidth * 0.09 },
                        10: { cellWidth: totalWidth * 0.12 },
                    },
                })
            }
            descriptionOptions.startY = pdf.autoTable.previous.finalY + 5
        })

        // Add totals header
        pdf.setFont('helvetica', 'bold')
        pdf.setFillColor(230, 230, 230)
        pdf.rect(
            10,
            descriptionOptions.startY,
            pdf.internal.pageSize.width - 20,
            10,
            'F'
        )

        const headers = [
            'TOTAL',
            `PACKAGES: ${index}`,
            `ITEMS: ${Number(
                useSuggested ? totalSuggestedItems : totalItems
            ).toFixed(0)}`,
            `NET MASS: ${formatNumber(
                Number(useSuggested ? netSuggestedMass : netMass)
            )} Kg`,
            `GROSS MASS: ${formatNumber(
                Number(useSuggested ? totalSuggestedMass : totalMass)
            )} Kg`,
        ]
        const cellWidths = [
            totalWidth * 0.12,
            totalWidth * 0.18,
            totalWidth * 0.16,
            totalWidth * 0.26,
            totalWidth * 0.28,
        ]

        let currentX = 0
        headers.forEach((header, index) => {
            pdf.text(header, currentX + 15, descriptionOptions.startY + 6)
            currentX += cellWidths[index]
        })

        const marginLeft = 20
        const marginRight = pdf.internal.pageSize.width - 20
        const signatureLineY = descriptionOptions.startY + 30
        const labelY = signatureLineY + 5

        pdf.line(marginLeft, signatureLineY, marginLeft + 80, signatureLineY)
        pdf.line(marginRight - 80, signatureLineY, marginRight, signatureLineY)

        pdf.text('Delivers', marginLeft, labelY)
        pdf.text('Receives', marginRight - 80, labelY)

        const pdfDataUri = pdf.output('datauristring')
        const blob = dataURItoBlob(pdfDataUri)
        const blobURL = URL.createObjectURL(blob)
        const printWindow = window.open(blobURL)
        printWindow.onload = function() {
            printWindow.print()
            URL.revokeObjectURL(blobURL)
        }
    } catch (error) {
        console.log(error)
    }
}

const getAspectRatio = async file => {
    return new Promise(function(resolved) {
        var i = new Image()
        i.onload = function() {
            resolved(i.width / i.height)
        }
        i.src = file
    })
}

const dataURItoBlob = dataURI => {
    const byteString = Uint8Array.from(atob(dataURI.split(',')[1]), c =>
        c.charCodeAt(0)
    )
    const blobType = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]
    return new Blob([byteString], { type: blobType })
}

const findUserInfo = (users, id) => {
    let user = { name: '', phone: '' }
    const userFound = users.find(u => u.id == id)
    if (userFound) {
        user = userFound
    }
    return user
}

const processFinish = (item, settingFinish) => {
    if (item.finishAndUCCode && settingFinish) {
        const finish = settingFinish.finishes.find(
            e => e.id === item.finishAndUCCode
        )
        if (finish && finish.description) {
            return `${finish.description}`
        } else {
            return '-'
        }
    } else {
        return '-'
    }
}
const formatNumber = value => {
    value = Number(value)
    if (isNaN(value)) {
        return value
    }
    return value.toFixed(2)
}
